.card {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  background-color: #fff;
  width: 47.8125vh;
  height: 85vh;
  padding: 0 1rem 1rem 1rem;
  border-radius: 10px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.actionBtn {
  margin-top: 77vh;
}
