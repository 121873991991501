body {
  overflow-x: hidden;
}

.swype-header {
  z-index: 9999 !important; 
  background: #191160;
    -webkit-box-shadow: 0px 10px 18px -6px rgba(0,0,0,0.48);
  -moz-box-shadow: 0px 10px 18px -6px rgba(0,0,0,0.48);
  box-shadow: 0px 10px 18px -6px rgba(0,0,0,0.48);
  height: 65px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

/* center a div */
.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}