.sms-btn {
  background-color: #128C7E;
  color: white;
}

.call-btn {
  background-color: #128C7E;
  color: white;
}

.whatsapp-btn {
  background-color: #128C7E;
  color: white;
}

.custom-btn {
  background-color: white;
  color: black;
}